import React, { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import { useState } from 'react';
import './App.css';
import './fonts.css';

export default function App() {
  const [language, setLanguage] = useState('Ua');

  // движение страниц
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [time, setTime] = useState(true);

  // открытие и закрытие меню
  const [menuWidth, setMenuWidth] = useState(false);

  // блокировка прокрутки
  const [block, setBlock] = useState(false);

  return (
    <Context.Provider value={{ one, two, third, fourth, time, setOne, setTwo, setThird, setFourth, setTime, language, setLanguage, menuWidth, setMenuWidth, block, setBlock }}>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<MainRoute />} />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
}
