import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import Home from './Home/Home';
import './Main.css';
import Portfolio from './Portfolio/Portfolio';
import Workflow from './Workflow/Workflow';
import Footer from './Footer/Footer';
// import { useLocation } from 'react-router-dom';
import OurProducts from './OurProducts/OurProducts';

export default function Main() {
    const { one, two, third, fourth, time, setOne, setTwo, setThird, setFourth, setTime, menuWidth, block } = useContext(Context);
    const [previousY, setPreviousY] = useState(0);

    // const location = useLocation();

    // useEffect(() => {
    //     setTime(false);
    //     setTimeout(() => {
    //         setTime(true);
    //     }, 1000);
    //     // eslint-disable-next-line
    // }, [location]);

    // дополительный переключатель
    const [scrollValues, setScrollValues] = useState(false);

    const handleWheel = (event) => {

        const flagOne = scrollValues[0] < (scrollValues[1] + 1);
        const flagTwo = scrollValues[0] > (scrollValues[1] - 1);

        const deltaY = event.deltaY;

        // Добавляем значение прокрутки в массив
        setScrollValues(prevValues => {
            // Создаем новый массив с новыми значениями прокрутки
            const newValues = [prevValues[1], deltaY];
            return newValues;
        });


        if (time) {
            setTime(false);

            const deltaY = event.deltaY;

            if (deltaY > 0 && !one && flagOne) {
                setOne(true);
                setScrollValues(false)
            } else if (deltaY > 0 && one && !two && flagOne) {
                setTwo(true);
                setScrollValues(false)
            } else if (deltaY > 0 && one && two && !third && flagOne) {
                setThird(true);
                setScrollValues(false)
            } else if (deltaY > 0 && one && two && third && !fourth && flagOne) {
                setFourth(true);
                setScrollValues(false)
            } else if (deltaY < 0 && one && two && third && fourth && flagTwo) {
                setFourth(false);
                setScrollValues(false)
            } else if (deltaY < 0 && one && two && third && !fourth && flagTwo) {
                setThird(false);
                setScrollValues(false)
            } else if (deltaY < 0 && one && two && !third && !fourth && flagTwo) {
                setTwo(false);
                setScrollValues(false)
            } else if (deltaY < 0 && one && !two && !third && !fourth && flagTwo) {
                setOne(false);
                setScrollValues(false)
            }

            setTimeout(() => {
                setTime(true);
            }, 200);
        }
    };

    // чуствительность срабатывания на телефоне
    const threshold = 7;

    const handleTouchMove = (event) => {
        if (time) {
            setTime(false);

            const currentY = event.touches[0].clientY;
            const deltaY = currentY - previousY;

            if (Math.abs(deltaY) >= threshold) {
                if (deltaY > 0) {
                    // Логика прокрутки вниз
                    if (one && two && third && fourth) {
                        setFourth(false);
                    } else if (one && two && third && !fourth) {
                        setThird(false);
                    } else if (one && two && !third && !fourth) {
                        setTwo(false);
                    } else if (one && !two && !third && !fourth) {
                        setOne(false);
                    }
                } else if (deltaY < 0) {
                    // Логика прокрутки вверх
                    if (!one) {
                        setOne(true);
                    } else if (one && !two) {
                        setTwo(true);
                    } else if (one && two && !third) {
                        setThird(true);
                    } else if (one && two && third && !fourth) {
                        setFourth(true);
                    }
                }

                setPreviousY(currentY);
            }

            setTimeout(() => {
                setTime(true);
            }, 1500);
        }
    };

    const handleTouchStart = (event) => {
        setPreviousY(event.touches[0].clientY);
    };

    // useEffect(() => {
    //     if (!menuWidth && !block && time) {
    //         window.addEventListener('wheel', handleWheel);
    //         window.addEventListener('touchstart', handleTouchStart);
    //         window.addEventListener('touchmove', handleTouchMove);

    //         return () => {
    //             window.removeEventListener('wheel', handleWheel);
    //             window.removeEventListener('touchstart', handleTouchStart);
    //             window.removeEventListener('touchmove', handleTouchMove);
    //         };
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [one, two, third, time, previousY, menuWidth, block]);

    return (
        <div className='main'>
            <Home position={one} />
            {/* <OurProducts position={[one, two]} />
            <Portfolio position={[two, third]} />
            <Workflow position={[third, fourth]} />
            <Footer position={[fourth]} /> */}
        </div>
    );
}
