import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './Container/Container';
import Main from './Main/Main';
// import List from './Main/Portfolio/List/List';
// import Faq from './Main/Faq/Faq';
// import Contacts from './Main/Contacts/Contacts';
// import Cases from './Main/Portfolio/Cases/Cases';
// import About from './Main/About/About';
// import Offer from './Main/Offer/Offer';

export default function MainRoute() {
    return (
        <Container>
            <Routes>
                <Route path='/' element={<Main />} />
                {/* <Route path='/en' element={<Main />} />
                <Route path='/portfolio' element={<List />} />
                <Route path='/en/portfolio' element={<List />} />
                <Route path='/portfolio/cases' element={<Cases />} />
                <Route path='/en/portfolio/cases' element={<Cases />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/en/faq' element={<Faq />} />
                <Route path='/contacts' element={<Contacts />} />
                <Route path='/en/contacts' element={<Contacts />} />
                <Route path='/about' element={<About />} />
                <Route path='/en/about' element={<About />} />
                <Route path='/business-card-site' element={<Offer />} />
                <Route path='/en/business-card-site' element={<Offer />} />
                <Route path='/catalog' element={<Offer />} />
                <Route path='/en/catalog' element={<Offer />} />
                <Route path='/internet-store' element={<Offer />} />
                <Route path='/en/internet-store' element={<Offer />} /> */}
            </Routes>
        </Container>
    );
}
