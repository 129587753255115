import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../Context';
import './Home.css';
import { useMediaQuery } from 'react-responsive';

export default function Home(props) {
  const { setOne, setTime, language } = useContext(Context);
  const [description, setDescription] = useState(false);

  const isIpad = useMediaQuery({ maxWidth: 1200 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    if (!props.position) {
      setTimeout(() => {
        setDescription(true);
      }, 500);
    } else {
      setDescription(false);
    }
  }, [props.position]);

  const handleArrowOne = () => {
    setOne(true);
    setTime(false);

    setTimeout(() => {
      setTime(true);
    }, 500);
  };

  const texts = {
    ua: {
      // description:
      //   'Ми змінимо ваше уявлення про сучасний веб-сайт. \n  \n Про що наші сайти? Саме про  вас, ваш продукт і ваш бізнес! Наша команда експертів з веб- розробки і дизайну готова втілювати ваші проєкти в життя. Ми створюємо унікальні веб- сайти, які виділяють ваш бренд серед конкурентів.',
      description: 'Ми змінимо ваше уявлення про сучасний веб-сайт.',
    },
    en: {
      description:
        'We will change the way you think about the modern website.  \n  \n What are our sites about? It`s about you, your product and your business! Our team of web development and design experts is ready to bring your projects to life. We create unique websites that set your brand apart from the competition.',
    },
  };

  const languageData = texts[language.toLowerCase()];

  return (
    <section className={`home ${props.position ? 'home-one' : ''}`}>
      <p className={`home-gradient-text ${description ? 'home-gradient-text-active' : ''}`}>Coming soon</p>

      {/* <h1 className={`home-description ${description ? 'home-description-active' : ''}`}>Circle studio - {languageData.description}</h1> */}
      <h1 className={`home-description ${description ? 'home-description-active' : ''}`}>{languageData.description}</h1>

      <div className={`home__picture ${props.position ? 'home__picture-one' : ''}`}>
        <img className="home__img" src={isIphone ? '../img/home-ipad.svg' : isIpad ? '../img/home-ipad.svg' : '../img/home.png'} alt="img" />
        {/* <div onClick={handleArrowOne} className={`arrow__picture ${description ? 'arrow__picture-active' : ''}`}>
          <img className="arrow__img-circle" src="../img/circle.svg" alt="" />
        </div> */}
      </div>
    </section>
  );
}
