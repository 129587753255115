import React from 'react';
import Menu from '../Menu/Menu';
import './Container.css';

export default function Container({ children }) {
    return (
        <div className='container'>
            <Menu />
            {children}
        </div>
    );
}