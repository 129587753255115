const texts = {
  ua: {
    cntct: 'cntct us',
    learnMore: 'спитати',
    ask: 'Поговоримо?',
    personName: 'Ім`я',
    errorsName: 'Треба ввести ім`я',
    number: 'Напишіть номер або пошту',
    errorsNumber: 'Додайте номер телефону',
    comment: 'Коментарі',
    errorsComment: 'Чекаємо на ваш коментар',
    consent: 'Я погоджуюсь на обробку персональних даних',
    send: 'Відправити',
    success: 'Ми отримали повідомлення',
    businessСardSite: 'Сайт візитка',
    catalog: 'Каталог',
    store: 'Інтернет магазин',
    faq: 'Популярні питання',
    aboutAs: 'Про нас',
    contacts: 'Контакти',
    pack: 'Оберіть пакет:',
    infoOneBasic: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг/сервер</p>
        <p>• розробка індивідуального дизайну за наявною бренд айдентикою</p>
        <p>• наповнення наданим контентом</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• тесутвання, багфікс</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p className="info-main__price">від 12 000 грн</p>
      </>
    ),
    infoOneBasicMessege: 'Хочу дізнатися більше про Сайт візитка пакета Basic',
    infoOnePro: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг / сервер</p>
        <p>• аналіз цільової аудиторії та конкурентів</p>
        <p>• пропрацювання бренд айдентики</p>
        <p>• допомога при створенні контенту</p>
        <p>• дві концепції дизайну на вибір</p>
        <p>• мовні версії</p>
        <p>• форма зворотнього зв`язку</p>
        <p>• впровадження базової SEO оптимізації</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p>• тесутвання, багфікс</p>
        <p className="info-main__price">від 15 000 грн</p>
      </>
    ),
    infoOneProMessege: 'Хочу дізнатися більше про Сайт візитка пакета Pro',
    infoOneTitle:
      'Сайт візитка є невеликим, зазвичай односторінковим веб-сайтом, який використовується для представлення інформації про особу, компанію чи послуги. Головна мета такого сайту - надати коротку, але інформативну візитку для відвідувачів.',
    infoTwoBasic: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг/сервер</p>
        <p>• розробка індивідуального дизайну за наявною бренд айдентикою</p>
        <p>• наповнення наданим контентом</p>
        <p>• базові фільтри</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• адміністративна панель для керування та редагування контенту сайту</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p>• тесутвання, багфікс</p>
        <p className="info-main__price">від 15 000 грн</p>
      </>
    ),
    infoTwoBasicMessege: 'Хочу дізнатися більше про Каталог пакета Basic',
    infoTwoPro: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг / сервер</p>
        <p>• аналіз цільової аудиторії та конкурентів</p>
        <p>• пропрацювання бренд айдентики</p>
        <p>• допомога при створенні контенту</p>
        <p>• дві концепції дизайну на вибір</p>
        <p>• мовні версії</p>
        <p>• фільтри різного типу</p>
        <p>• функція пошуку по сайту</p>
        <p>• форма зворотнього звязку з повідомленням для адміністатора</p>
        <p>• додаткові послуги: карта пунктів видачі, блог, схожі товари</p>
        <p>• впровадження базової SEO оптимізації</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• адміністративна панель для керування та редагування контенту сайту</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p>• тесутвання, багфікс</p>
        <p className="info-main__price">від 20 000 гр</p>
      </>
    ),
    infoTwoProMessege: 'Хочу дізнатися більше про Каталог пакета Pro',
    infoTwoTitle:
      'Сайт каталог - це веб-сайт, де товари або послуги представлені у вигляді структурованого переліку. Клієнти можуть переглядати, шукати та дізнаватися деталі про продукцію чи послуги. Зазвичай включає функції фільтрації та сортування для зручності навігації.',
    infoThreeBasic: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг / сервер</p>
        <p>• розробка індивідуального дизайну за наявною бренд айдентикою</p>
        <p>• наповнення наданим контентом</p>
        <p>• базові фільтри</p>
        <p>• функція кошику</p>
        <p>• каталог товарів</p>
        <p>• форма зворотнього звязку з повідомленням для адміністатора</p>
        <p>• адміністративна панель для керування та редагування контенту сайту</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p>• тесутвання, багфікс</p>
        <p className="info-main__price">від 25 000 гр</p>
      </>
    ),
    infoThreeBasicMessege: 'Хочу дізнатися більше про Інтернет магазин пакета Basic',
    infoThreePro: (
      <>
        <p>• доменне імʼя</p>
        <p>• встановлення сайту на хостинг / сервер</p>
        <p>• аналіз цільової аудиторії та конкурентів</p>
        <p>• пропрацювання бренд айдентики</p>
        <p>• допомога при створенні контенту</p>
        <p>• дві концепції дизайну на вибір</p>
        <p>• мовні версії</p>
        <p>• фільтри різного типу</p>
        <p>• функція пошуку по сайту</p>
        <p>• підключення CRM систем</p>
        <p>• кошик з оплатою онлайн</p>
        <p>• форма зворотнього звязку з повідомленням для адміністатора</p>
        <p>• адміністративна панель для керування та редагування контенту сайту</p>
        <p>• додаткові послуги: схожі товари, порівняння 2х товарів за характеристиками, калькулятор цін, блог, список пунктів видачі (наприклад НП), підписка на розсилку</p>
        <p>• впровадження базової SEO оптимізації</p>
        <p>• адаптивність під різні пристрої</p>
        <p>• підключення шифрування "https" для безпеки сайту</p>
        <p>• тесутвання, багфікс</p>
        <p className="info-main__price">від 30 000 гр</p>
      </>
    ),
    infoThreeProMessege: 'Хочу дізнатися більше про Інтернет магазин пакета Pro',
    infoThreeTitle:
      'Інтернет магазин - це веб-сайт, де товари чи послуги можна переглядати, обирати та придбавати в Інтернеті. Такий сайт надає можливість клієнтам здійснювати покупки онлайн, додаючи товари до кошика, обираючи різні параметри та використовуючи онлайн-платіжні системи. Інтернет-магазини забезпечують зручний та ефективний спосіб для покупців придбати товари чи послуги, не виходячи з дому.',
  },
  en: {
    cntct: 'cntct us',
    learnMore: 'learn more',
    ask: 'ASK FOR INFORMATION',
    personName: 'Name',
    errorsName: 'You need to enter a name',
    number: 'Write the number or mail',
    errorsNumber: 'Add your phone number',
    comment: 'Comments',
    errorsComment: 'Write down your comment',
    consent: 'I consent to the processing of personal data',
    send: 'Send',
    success: 'Success',
    businessСardSite: 'Business card site',
    catalog: 'Catalog website',
    store: 'Internet store',
    faq: 'FAQ',
    aboutAs: 'About Us',
    contacts: 'Contacts',
    pack: 'Select package:',
    infoOneBasic: (
      <>
        <p>• creation of a site according to a universal template based on your brand - beech</p>
        <p>• adaptation for all devices</p>
        <p>• filling with your content</p>
        <p>• lead time 2 weeks</p>
        <p className="info-main__price">260 $</p>
      </>
    ),
    infoOneBasicMessege: 'I want to know more about the Basic package business card website',
    infoOnePro: (
      <>
        <p>• development of a unique concept and development of brand identity</p>
        <p>• a choice of 2 design options created especially for you</p>
        <p>• adaptation for all devices</p>
        <p>• language versions</p>
        <p>• help with content creation and filling</p>
        <p>• lead time 3 weeks</p>
        <p className="info-main__price">310 $</p>
      </>
    ),
    infoOneProMessege: 'I want to know more about the Pro package business card website',
    infoTwoBasic: (
      <>
        <p>• creation of a site according to a universal template based on your brand - beech</p>
        <p>• adaptation for all devices</p>
        <p>• filling with your content</p>
        <p>• additional pages</p>
        <p>• lead time 2 weeks</p>
        <p className="info-main__price">315 $</p>
      </>
    ),
    infoTwoBasicMessege: 'I want to learn more about the Basic package catalog',
    infoTwoPro: (
      <>
        <p>• development of a unique concept and development of brand identity</p>
        <p>• a choice of 2 design options created especially for you</p>
        <p>• design of each individual page of the site</p>
        <p>• development of a database to store your content</p>
        <p>• adaptation for all devices</p>
        <p>• administrative panel for managing and editing site content</p>
        <p>• language versions</p>
        <p>• messages in Telegram and e-mail when the user fills in his data on the site</p>
        <p>• help with content creation and filling</p>
        <p>• lead time 4 weeks</p>
        <p className="info-main__price">440 $</p>
      </>
    ),
    infoTwoProMessege: 'I want to learn more about the Pro package catalog',
    infoThreeBasic: (
      <>
        <p>• creation of a site according to a universal template based on your brand - beech</p>
        <p>• adaptation for all devices</p>
        <p>• filling with your content</p>
        <p>• additional pages</p>
        <p>• product filter</p>
        <p>• development of a database to store your content</p>
        <p>• administrative panel for managing and editing product content</p>
        <p>• lead time 4 weeks</p>
        <p className="info-main__price">445 $</p>
      </>
    ),
    infoThreeBasicMessege: 'I want to learn more about the online store of the Basic package',
    infoThreePro: (
      <>
        <p>• development of a unique concept and development of brand identity</p>
        <p>• a choice of 2 design options created especially for you</p>
        <p>• design of each individual page of the site</p>
        <p>• development of a database to store your content</p>
        <p>• adaptation for all devices</p>
        <p>• development of a database to store your content</p>
        <p>• administrative panel for managing and editing product content</p>
        <p>• a unique implementation of the comparison system and product filters for your needs</p>
        <p>• language versions</p>
        <p>• help with content creation and filling</p>
        <p>• development of the basket function</p>
        <p>• messages in Telegram and e-mail when the user fills in his data on the site</p>
        <p>• implementation of payment systems and the possibility of online shopping</p>
        <p>• lead time is 5 weeks</p>
        <p className="info-main__price">525 $</p>
      </>
    ),
    infoThreeProMessege: 'I want to learn more about the online store of the Pro package',
  },
};

export default texts;