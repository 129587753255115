import React, { useContext, useEffect, useState } from 'react';
import './Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import CloseIcon from '@mui/icons-material/Close';
import { Context } from '../../Context';
import { useLocation } from 'react-router-dom';
import texts from './Texts';
import { useNavigate } from 'react-router-dom';

export default function Menu() {
  const { fourth, language, setLanguage, menuWidth, setMenuWidth, block, setBlock } = useContext(Context);
  const [languageСhoice, setLanguageСhoice] = useState('');
  const [addClass, setAddClass] = useState(false);
  const [flag, setFlag] = useState(true);
  const [thirdActive, setThirdActive] = useState(true);

  // главные категории
  const [menuOne, setMenuOne] = useState(false);
  const [menuTwo, setMenuTwo] = useState(false);
  const [menuThree, setMenuThree] = useState(false);

  // уменьшение логотипа
  const [logoSmall, setlogoSmall] = useState(false);
  // информация пакета
  const [packageInfo, setPackageInfo] = useState(false);

  // наведения на ссылках
  const [linksOne, setIsHoveredLinksOne] = useState(false);
  const [linksTwo, setIsHoveredLinksTwo] = useState(false);
  const [linksThree, setIsHoveredLinksThree] = useState(false);

  const [category, setCategory] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [subcategory, setSubcategory] = useState(false);

  // contact-us
  const [contactUsActive, setContactUsActive] = useState(false);
  const [contactUsTop, setContactUsTop] = useState(false);
  const [contactUsLeft, setContactUsLeft] = useState(false);

  const [contactUsSend, setContactUsSend] = useState(false);
  const [contactUsSendFinish, setContactUsSendFinish] = useState(false);

  const [inputOpen, setInputOpen] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);

  // определяем на каком мы пути
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'en') {
      setLanguage('En');
    } else if (pathParts[1] === '/') {
      setLanguage('Ua');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // отключение cntct
  useEffect(() => {
    if (fourth && location.pathname === '/') {
      setThirdActive(false);
    } else if (fourth && location.pathname === '/en') {
      setThirdActive(false);
    } else if (
      location.pathname === '/business-card-site' ||
      location.pathname === '/catalog' ||
      location.pathname === '/internet-store' ||
      location.pathname === '/en/business-card-site' ||
      location.pathname === '/en/catalog' ||
      location.pathname === '/en/internet-store'
    ) {
      setTimeout(() => {
        setThirdActive(false);
      }, 500);
    } else {
      setTimeout(() => {
        setThirdActive(true);
      }, 1000);
    }
  }, [fourth, location]);

  const selectedLanguageData = texts[language.toLowerCase()];

  const handleUa = () => {
    setAddClass(!addClass);
    setFlag(!flag);
    if (!flag) setLanguageСhoice('');
    setTimeout(() => {
      if (flag) {
        if (language === 'En') {
          setLanguageСhoice('Ua');
        } else {
          setLanguageСhoice('En');
        }
      }
    }, 500);
  };

  const handleEn = () => {
    setFlag(!flag);
    setAddClass(!addClass);
    setLanguageСhoice('');
    setTimeout(() => {
      if (language === 'En') {
        window.history.replaceState(null, null, window.location.href.replace('/en', ''));
        window.location.reload();
      } else {
        window.location.href = window.location.href.replace(/^(https?:\/\/[^/]+)(\/.*)?$/, '$1/en$2');
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    if (addClass) {
      setTimeout(() => {
        setAddClass(!addClass);
        setFlag(!flag);
        if (!flag) setLanguageСhoice('');
        setTimeout(() => {
          if (flag) {
            if (language === 'En') {
              setLanguageСhoice('Ua');
            } else {
              setLanguageСhoice('En');
            }
          }
        }, 500);
      }, 1000);
    }
  };

  const handleMenu = () => {
    if (menuOpen) {
      // исчезновение кнопки в футере
      if (fourth) {
        setTimeout(() => {
          setThirdActive(false);
        }, 500);
      }
      setFormData({ name: '', number: '', comment: '', consent: false });
      setlogoSmall(false);

      setMenuOpen(false);
      setTimeout(() => {
        setCategory(false);
        setMenuWidth(false);
        setSubcategory(false);

        setPackageInfo(false);
      }, 500);

      if (
        location.pathname === '/business-card-site' ||
        location.pathname === '/catalog' ||
        location.pathname === '/internet-store' ||
        location.pathname === '/en/business-card-site' ||
        location.pathname === '/en/catalog' ||
        location.pathname === '/en/internet-store'
      ) {
        setTimeout(() => {
          setThirdActive(false);
        }, 500);
      }
    } else {
      // появление кнопки в футере
      setTimeout(() => {
        setThirdActive(true);
      }, 500);

      setMenuWidth(true);

      setTimeout(() => {
        setCategory(true);
      }, 300);

      setTimeout(() => {
        setMenuOpen(true);
        setSubcategory(true);
      }, 500);
    }
  };

  // кнопки перехода по ссылкам
  const navigate = useNavigate();

  // ссылки страниц
  const handleLinks = (props) => {
    // document.getElementById("top").scrollIntoView();
    navigate(props);
    setMenuOpen(false);
    setTimeout(() => {
      setCategory(false);
      setMenuWidth(false);
      setSubcategory(false);

      setPackageInfo(false);
    }, 500);
  };

  const handleContactUs = () => {
    setBlock(!block);
    if (contactUsActive) {
      setContactUsTop(false);
      setContactUsLeft(false);
      setInputOpen(false);
      setTimeout(() => {
        setContactUsActive(false);
      }, 300);
    } else {
      setContactUsActive(true);
      setTimeout(() => {
        setContactUsTop(true);
        setContactUsLeft(true);
      }, 300);

      setTimeout(() => {
        setInputOpen(true);
      }, 800);
    }
  };

  // данные отправки
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    comment: '',
    consent: false,
  });

  // ошибки
  const [errors, setErrors] = useState({
    name: false,
    number: false,
    comment: false,
    consent: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    fetch('https://messenger-8mp2.onrender.com/circle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  };

  // кнопка отправки
  const handleSend = () => {
    const newErrors = {
      name: formData.name === '',
      number: formData.number === '',
      comment: formData.comment === '',
      consent: !formData.consent,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    // setContactUsSend(true);
    // setContactUsTop(false);
    // setInputOpen(false);
    setSendSuccess(true);

    setTimeout(() => {
      setContactUsTop(false);
      setInputOpen(false);
      setBlock(!block);
      // setContactUsSendFinish(true);
      setContactUsActive(false);
      setContactUsLeft(false);

      setContactUsSend(false);
      setSendSuccess(false);

      // setTimeout(() => {
      //   setContactUsSendFinish(false);
      // }, 300);

      setFormData({
        name: '',
        number: '',
        comment: '',
        consent: false,
      });
    }, 1500);

    handleFormSubmit(new Event('submit'));
  };

  // кнопка логотипа
  const handleLogo = () => {
    localStorage.removeItem('portfolioList');

    if (language === 'Ua') {
      window.location.assign('/');
    } else {
      window.location.assign('/en');
    }
  };

  return (
    <>
      <header className={`menu ${menuOpen ? 'menu-open' : ''} ${menuWidth ? 'menu-active' : ''}`}>
        <div onClick={handleLogo} className={`menu-logo ${menuWidth ? 'menu-logo-active' : ''} ${logoSmall ? 'menu-logo-small' : ''}`}>
          <img className="menu-logo__picture" src="/img/logo-menu.svg" alt="logo" />
        </div>

        {/* <div className="menu-button" onClick={handleMenu}>menu</div>

        {category && (
          <div className="menu-category">
            <div className={`menu-category__black ${menuOpen ? 'menu-category__black-open' : ''}`}></div>

            {language === 'Ua' && (
              <div className={`menu-category__text-hover menu-category__text-hover-${menuOne ? 'one-ua' : menuTwo ? 'two-ua' : menuThree ? 'three-ua' : ''}`}>
                <img className="menu-category__text-hover-img" src="../img/circle.svg" alt="" />
              </div>
            )}
            {language === 'En' && (
              <div className={`menu-category__text-hover menu-category__text-hover-${menuOne ? 'one' : menuTwo ? 'two' : menuThree ? 'three' : ''}`}>
                <img className="menu-category__text-hover-img" src="../img/circle.svg" alt="" />
              </div>
            )}

            <h2
              onMouseEnter={() => setMenuOne(true)}
              onMouseLeave={() => setMenuOne(false)}
              onClick={() => handleLinks(language === 'Ua' ? '/business-card-site' : '/en/business-card-site')}
              className="menu-category__text"
            >
              {selectedLanguageData.businessСardSite}
            </h2>

            <h2
              onMouseEnter={() => setMenuTwo(true)}
              onMouseLeave={() => setMenuTwo(false)}
              onClick={() => handleLinks(language === 'Ua' ? '/catalog' : '/en/catalog')}
              className="menu-category__text"
            >
              {selectedLanguageData.catalog}
            </h2>

            <h2
              onMouseEnter={() => setMenuThree(true)}
              onMouseLeave={() => setMenuThree(false)}
              onClick={() => handleLinks(language === 'Ua' ? '/internet-store' : '/en/internet-store')}
              className="menu-category__text"
            >
              {selectedLanguageData.store}
            </h2>
          </div>
        )} */}

        {/* <div className="menu-language" onMouseLeave={handleMouseLeave}>
          <div className={`menu-language__en ${addClass ? 'position' : ''}`} onClick={handleEn}>
            {languageСhoice}
          </div>
          <div className="menu-language__ua" onClick={handleUa}>
            {language}
          </div>
        </div> */}

        <div onClick={handleContactUs} className={`menu-contact-us-black ${contactUsActive ? 'menu-contact-us-black-active' : ''}`}></div>
        {thirdActive && (
          <div
            className={`menu-contact-us-top ${contactUsActive ? 'menu-contact-us-top-active' : ''} ${contactUsTop ? 'menu-contact-us-top-full' : ''} ${
              contactUsSendFinish ? 'menu-contact-us-top-finish' : ''
            }`}
          >
            {inputOpen && <h3 className="menu-contact-us-top-title">{selectedLanguageData.ask}</h3>}

            {inputOpen && (
              <input
                className={`menu-contact-us-top-input ${errors.name ? 'error' : ''}`}
                type="text"
                placeholder={errors.name ? selectedLanguageData.errorsName : selectedLanguageData.personName}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            )}
            {inputOpen && (
              <input
                className={`menu-contact-us-top-input ${errors.number ? 'error' : ''}`}
                type="text"
                placeholder={errors.number ? selectedLanguageData.errorsNumber : selectedLanguageData.number}
                name="number"
                value={formData.number}
                onChange={handleChange}
              />
            )}
            {inputOpen && (
              <input
                className={`menu-contact-us-top-input ${errors.comment ? 'error' : ''}`}
                type="text"
                placeholder={errors.comment ? selectedLanguageData.errorsComment : selectedLanguageData.comment}
                name="comment"
                value={formData.comment}
                onChange={handleChange}
              />
            )}

            {inputOpen && (
              <label className="menu-contact-us-top-checkbox__label">
                <input
                  className={`menu-contact-us-top-checkbox ${errors.consent ? 'error-checkbox' : ''}`}
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                />
                {selectedLanguageData.consent}
              </label>
            )}

            {inputOpen && (
              <button onClick={handleSend} className="menu-contact-us-button">
                {sendSuccess ? selectedLanguageData.success : contactUsTop ? selectedLanguageData.send : ''}
              </button>
            )}
          </div>
        )}

        {/* {thirdActive && (
          <div
            onClick={handleSend}
            className={`menu-contact-us-left
         ${contactUsActive ? 'menu-contact-us-left-active' : ''}
          ${contactUsLeft ? 'menu-contact-us-left-full' : ''}
           ${contactUsSend ? 'menu-contact-us-left-send' : ''}
            ${contactUsSendFinish ? 'menu-contact-us-left-finish' : ''}`}
          >
            {' '}
            {contactUsTop ? selectedLanguageData.send : sendSuccess ? selectedLanguageData.success : ''}
          </div>
        )} */}

        {thirdActive && (
          <div
            onClick={handleContactUs}
            className={`menu-contact-us ${contactUsActive ? 'menu-contact-us-white__border' : ''} ${subcategory ? 'menu-contact-us-open' : ''} ${
              logoSmall ? 'menu-contact-us-by' : ''
            }`}
          >
            {contactUsActive ? <CloseIcon /> : logoSmall ? selectedLanguageData.learnMore : selectedLanguageData.cntct}
          </div>
        )}
        {thirdActive && <div className={`menu-contact-us__circle ${subcategory ? 'menu-contact-us__circle-open' : ''}`}></div>}
      </header>

      {/* {subcategory && (
        <div className="menu-subcategory">
          <div className={`menu-subcategory__wrapper ${packageInfo ? 'menu-subcategory__wrapper-open' : ''}`}>
            {!packageInfo && (
              <div className="menu-subcategory__links">
                <div
                  className={`menu-subcategory__links-hover menu-subcategory__links-hover-${
                    language === 'Ua' ? (linksOne ? 'one-ua' : linksTwo ? 'two-ua' : linksThree ? 'three-ua' : '') : linksOne ? 'one' : linksTwo ? 'two' : linksThree ? 'three' : ''
                  }`}
                >
                  <img className="menu-category__text-hover-img" src="../img/circle.svg" alt="" />
                </div>

                <div
                  className="menu-subcategory__links-item"
                  onClick={() => handleLinks(language === 'Ua' ? '/about' : '/en/about')}
                  onMouseEnter={() => setIsHoveredLinksOne(true)}
                  onMouseLeave={() => setIsHoveredLinksOne(false)}
                >
                  {selectedLanguageData.aboutAs}
                </div>

                <div
                  className="menu-subcategory__links-item"
                  onClick={() => handleLinks(language === 'Ua' ? '/faq' : '/en/faq')}
                  onMouseEnter={() => setIsHoveredLinksTwo(true)}
                  onMouseLeave={() => setIsHoveredLinksTwo(false)}
                >
                  {selectedLanguageData.faq}
                </div>

                <div
                  className="menu-subcategory__links-item"
                  onClick={() => handleLinks(language === 'Ua' ? '/contacts' : '/en/contacts')}
                  onMouseEnter={() => setIsHoveredLinksThree(true)}
                  onMouseLeave={() => setIsHoveredLinksThree(false)}
                >
                  {selectedLanguageData.contacts}
                </div>
              </div>
            )}

            {
              <div className="menu-subcategory__social">
                <a className="menu-subcategory__social-link" href="https://t.me/CircleWebStudio" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTelegram} className="menu-subcategory__social-link__item" />
                </a>

                <a className="menu-subcategory__social-link" href="https://www.instagram.com/glinkinzhenya/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="menu-subcategory__social-link__item" />
                </a>
              </div>
            }
          </div>
        </div>
      )} */}
    </>
  );
}
